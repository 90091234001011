import { MeInfo } from '../../../../Models/Me/Me';

export enum MeActionType {
    SET_INITIALIZED = 'app/me/set/initialized',
    SET_INFO = 'app/me/set/info',
}

export interface SetInitializedAction {
    type: typeof MeActionType.SET_INITIALIZED;
    payload: { initialized: boolean };
}

export interface SetInfoAction {
    type: typeof MeActionType.SET_INFO;
    payload: { info?: MeInfo; };
}

export type MeAction = SetInitializedAction | SetInfoAction;

