import React from 'react';

export interface CustomDialogContainerProps {
    containerId: string;
}

const CustomDialogContainer: React.FunctionComponent<CustomDialogContainerProps> = ({
    containerId,
}: CustomDialogContainerProps) => {
    return <div id={containerId} />;
};

export { CustomDialogContainer };
