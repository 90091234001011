//🇫🇷
export const fr = {
    ApplicationName: 'Detam',

    //* COMMONS
    COMMON_YES: 'Oui',
    COMMON_NO: 'Non',
    COMMON_REQUIRED_FIELD: 'Champ obligatoire*',
    COMMON_EMPTY_MESSAGE: 'Aucun résultat',
    COMMON_ADD: 'Ajouter',
    COMMON_CHANGE: 'Modifier',
    COMMON_BAD_VALUE_FIELD: 'Champ erroné',
    COMMON_EXPORT: 'Exporter',
    COMMON_IMPORT: 'Parcourir',
    COMMON_SAVE: 'Enregistrer',
    COMMON_CANCEL: 'Annuler',
    COMMON_DELETE: 'Supprimer',
    COMMON_SEARCH: 'Recherche',
    COMMON_SEARCH_TITLE: 'Rechercher',
    COMMON_PLACEHOLDER: '_',
    COMMON_ENABLED: 'Actif',
    COMMON_DISABLED: 'Inactif',
    COMMON_SAVE_SUCCESS: 'Enregistrement réussi !',

    //* ERRORS
    Error_GenericError: "Quelque chose ne s'est pas passé comme prévu, merci de réessayer",
    Error_EmptySharepointLink: 'Adresse du lien vide, veuillez saisir un lien valable',
    Error_RequiredFieldsCannotBeEmpty:
        'Erreur. Pour enregistrer votre saisie, tous les champs obligatoires doivent être saisis.',
    Error_AFFAIRE_NUMBER_DUPLICATION: 'Enregistrement impossible, ce code affaire est déjà utilisé',
    Error_INVALID_SP_LIBRARY_REFERENCE:
        'Le lien de la bibliothèque SharePoint n’est pas correct, pour enregistrer votre saisie vous devez le corriger',
    Error_COMPAGNON_INVALID_EMAIL_FORMAT: "Le format le l'email n'est pas correct",
    Error_CAUSERIE_ALREADY_PUBLISHED: 'Cette causerie a déjà été publiée',
    Error_AFFAIRE_ACTIVE_FIELDS_MISSING:
        "L'affaire ne peut pas être activée car il manque des champs obligatoire",
    Error_INCOMPLETE_CAUSERIE_PUBLICATION:
        'Pour publier la causerie, vous devez saisir au moins 1 titre, 1 description et joindre 1 pdf',
    Error_TEMPLATE_USED_BY_AFFAIRES: 'Le controle est utilisé dans une affaire',
    Error_TRIGRAM_INVALID_FORMAT: 'Un trigramme doit comporter 3 lettres',
    Error_FORBIDDEN: "Vous n'êtes pas authorisé à accéder au backoffice",

    User_FullName: '{firstName} {lastName}',

    Login_Login: 'Se connecter avec office 365',
    Header_Logout: 'Se déconnecter',

    Navbar_DayControles: 'Contrôles du jour',
    Navbar_Controles: 'Contrôles',
    Navbar_Pointages: 'Pointages',
    Navbar_Affaires: 'Affaires',
    Navbar_Causeries: 'Causeries',
    Navbar_Compagnons: 'Compagnons',
    Navbar_Message: 'Message',

    //* CONTROLE DU JOUR
    ControleListToday_Title: 'Contrôles du jour',
    ControleListToday_DateFormat: 'EEEE dd MMMM yyyy',

    //* CONTROLE TEMPLATES
    ControleList_Title: 'Contrôles',
    ControleList_ShowControlByEnabled: 'Afficher uniquement les contrôles actifs',
    ControleList_CreateControle: 'Créer un contôle',

    ControleList_Header_Controle: 'Contrôle',
    ControleList_Header_CreatedBy: 'Créé par',
    ControleList_Header_QuestionCount: 'Nb de questions',
    ControleList_Header_Type: 'Type',
    ControleList_Header_Status: 'Statut',

    //* CONTROLES
    ControleCreate_Title: 'Ajouter un contrôle',
    ControleCreate_PreviousTitle: 'Modèles de contrôles',
    ControleCreate_SuccessTitle: 'Bravo! le modèle de contrôle à été créee avec succès',

    ControleDetails_PreviousTitle: 'Modèles de contrôles',
    ControleDetails_DeleteTemplateTitle: 'Supprimer ce modèle de contrôle',
    ControleDetails_DeleteTemplateContent:
        'Êtes-vous sûr de vouloir supprimer ce modèle de contrôle ?',

    Controle_TabInfoTitle: 'Informations générales',
    Controle_TabQuestionsTitle: 'Questions',
    Controle_TabResultsTitle: 'Resultats',

    ControleInfo_Title: 'Nom de contrôle*',
    ControleInfo_IsActivated: 'Contrôle actif',
    ControleInfo_StateActive: 'Contrôle actif',
    ControleInfo_StateInactive: 'Contrôle inactif',
    ControleInfo_Description: 'Description',
    ControleInfo_Code: 'Code de contrôle*',
    ControleInfo_LinkedWithAffaire: 'Lié à une affaire?*',
    ControleInfo_OptionTitle: 'Option {number}*',
    ControleInfo_OptionTitle_Optional: 'Option {number}',
    ControleInfo_OptionCount: '{count} / 3',
    ControleInfo_DeleteTemplate: 'Supprimer le contrôle',

    ControleQuestions_SectionTitle: 'Nom de section*',
    ControleQuestions_QuestionNumberTitle: 'Question {number}',
    ControleQuestions_AddQuestion: 'Ajouter une question',
    ControleQuestions_AddSection: 'Ajouter une section',
    ControleQuestions_DeleteSection: 'Supprimer la section',
    ControleQuestion_Mandatory: 'Réponse obligatoire',
    ControleQuestion_Label_SingleChoice: 'Choix',
    ControleQuestion_Label_Text: 'Texte',
    ControleQuestion_Label_Date: 'Date',
    ControleQuestion_Label_Signature: 'Signature',

    ControleQuestions_DeleteSectionTitle: ' Supprimer cette section',
    ControleQuestions_DeleteSectionTitleContent:
        'Êtes-vous sûr de vouloir supprimer cette section ?',
    ControleQuestions_DeleteQuestionTitle: 'Supprimer cette question',
    ControleQuestions_DeleteQuestionTitleContent:
        'Êtes-vous sûr de vouloir supprimer cette question ?',

    ControleResults_FilterCompagnons: ' Compagnons',
    ControleResults_FilterAffaires: ' Affaires',
    ControleResults_FilterControles: 'Tous',
    ControleResults_FilterControles_DialogTitle: 'Conducteur de travaux',
    ControleResults_FilterControlesMobile: 'Tous',

    ControleInfo_NotInvalid: "Il y a des erreurs dans l'onglet 'Informations générales'",
    ControleQuestions_NotInvalid: "Il y a des erreurs dans l'onglet 'Questions'",

    Controle_GoToInformationTab: 'Voir les erreurs',
    Controle_GoToQuestionTab: 'Voir les erreurs',

    Controle_NoPDF: 'Aucun PDF disponible pour ce controle',
    Controle_NotDone: 'Non réalisé',
    
    Controle_PDF_Title: 'Titre PDF personnalisé',
    Controle_PDF_Warning: "Si vous choisissez un format personnalisé, il faudra impérativement mettre un champ texte dans le contrôle afin de définir le nouveau titre de l'export PDF.",
    Controle_Share: 'Activer le partage',
    Controle_Share_Warning: "Si vous ne choisissez pas au moins une option, aucun mail ne sera envoyé.",
    Controle_Email: 'Email de destination*',
    Controle_Options_Checkboxes: 'Si au moins une réponse dans :',
    Controle_Sharepoint: 'Enregistrer les résultats dans SharePoint',
    Controle_SharepointLink: 'Lien sharepoint*',

    //* AFFAIRES
    Affaire_CodeAndName: '{code} - {name}',
    Affaire_CreateTitle: 'Créer une affaire',
    Affaire_Veille: 'LES AFFAIRES DE LA VEILLE',
    Affaire_Today: 'TOUTES LES AFFAIRES ACTIVES',

    //* USERS
    Users_FirstAndLastName: '{firstName} {lastName}',
    Users_LastAndFirstName: '{lastName} {firstName}',
    Users_FirstAndLastNameContractType: '{firstName} {lastName} - {contractType}',

    //* DIALOG
    Dialog_LogoutTitle: 'Déconnexion',
    Dialog_LogoutMessage: 'Êtes-vous sur de vouloir vous déconnecter ?',

    //* TOAST
    Toast_GenericSuccessUpdateData: 'Les données ont été mises à jour',
    Toast_TitleSuccess: 'Succès!',
    Toast_TitleError: 'Erreur!',
    Toast_TitleInfo: 'Info!',

    //* DateRangePicker
    DatePicker_DateFormat: 'dd / MM / yyyy',
    DateRangePicker_StartLabel: 'Du',
    DateRangePicker_EndLabel: 'Au',

    //* ListAffaires
    ListAffaires_SwitchTitle: 'Afficher uniquement les affaires en cours',
    ListAffaires_CreateButtonTitle: 'Créer une affaire',
    ListAffaires_Code: 'Code',
    ListAffaires_Affaire: 'Affaire',
    ListAffaires_Status: 'Statut',

    //* AffaireDetails
    AffaireDetails_PreviousLabel: 'Affaires',
    AffaireDetails_TabInformationTitle: 'Informations générales',
    AffaireDetails_TabControlTitle: 'Contrôles',
    AffaireDetails_TabPointageTitle: 'Pointage',
    AffaireDetails_NameCodeFormat: '{code} - {name}',

    //* AffaireControl
    AffaireControl_DatetimeFormat: '{day} / {month} / {year} - {hour}h{minute}',
    AffaireControl_Empty: 'Aucun contrôle disponible',

    //* AffaireInformation
    AffaireInfo_name: "Nom de l'affaire*",
    AffaireInfo_code: "Code de l'affaire*",
    AffaireInfo_description: 'Description',
    AffaireInfo_photo: "Photo de l'affaire",
    AffaireInfo_Zones: "Zones agences*",
    AffaireInfo_Zone_Isb: 'ISB',
    AffaireInfo_Zone_Lom: 'LOM',
    AffaireInfo_Zone_Qua: 'QUA',
    AffaireInfo_Zone_Other: 'AUTRE',
    AffaireInfo_contactSPS: 'Contact SPS',
    AffaireInfo_conducteurTravaux: 'Conducteur de travaux',
    AffaireInfo_conducteurTravauxRequired: 'Conducteur de travaux*',
    AffaireInfo_address: 'Adresse',
    AffaireInfo_sharepointPlans: 'Lien Sharepoint (Exécution-PLANS)',
    AffaireInfo_sharepointPlanning: 'Lien Sharepoint (Budget heures)',
    AffaireInfo_sharepointOther: 'Lien Sharepoint (Prévention-SECU)',
    AffaireInfo_sharepointBL: 'Lien Sharepoint (BL)',
    AffaireInfo_sharepointPhotos: 'Lien Sharepoint (Photos)',
    AffaireInfo_dailyControls: 'Contrôles quotidien',
    AffaireInfo_openLinkButtonTitle: 'Ouvrir',
    AffaireInfo_allControlsListTitle: 'Contrôles affaire',
    AffaireInfo_selectedControlsListTitle: 'Contrôles quotidien',
    AffaireInfo_affaireStateActive: 'Affaire active',
    AffaireInfo_affaireStateInactive: 'Affaire inactive',
    AffaireInfo_errorRequiredFields:
        'Erreur. Pour enregistrer votre saisie, tous les champs obligatoires doivent être saisis.',
    AffaireInfo_badSharepointUrl:
        'Le lien de la bibliothèque SharePoint n’est pas correct, pour enregistrer votre saisie vous devez le corriger',

    //* AffairePointage
    AffairePointage_DateFormat: '{day} / {month} / {year}',

    //* Pointage
    Pointage_Monday: 'Lundi',
    Pointage_Tuesday: 'Mardi',
    Pointage_Wednesday: 'Mercredi',
    Pointage_Thursday: 'Jeudi',
    Pointage_Friday: 'Vendredi',
    Pointage_Total: 'TOTAL',
    Pointage_comments: 'COMMENTAIRES:',
    Pointage_WeekDateFormat: '{year}-{month}-{day}',
    Error_PointageHoursMustBe025: 'Les heures doivent être pointées par tranche de 0.25',
    Pointage_TooltipCreatedFormat: 'Pointé par {trigram} {dayText} {day}/{month}/{year} ({value})',
    Pointage_TooltipModifiedFormat:
        'Modifié par {trigram} {dayText} {day}/{month}/{year} ({value})',
    Pointage_SortInfoCompagnon: 'Compagnon',
    Pointage_VueAffaire: 'Vue affaire',
    Pointage_VueCompagnon: 'Vue compagnon',
    Pointage_Empty: 'Aucun pointage disponible',
    Pointage_SelectPlaceholder: 'Tous',
    Pointage_SelectCompagnonPlaceHolder: 'CMP',
    Pointage_SelectAffairePlaceHolder: 'Affaire',
    Pointage_SelectConducteurTravauxPlaceHolder: 'CT',
    Pointage_SelectTypePointagePlaceHolder: 'Type',
    Pointage_SelectedTypePointage: '{pointageType}',
    Pointage_SignaturePdfTitle: "Relevé d'heures {firstName} {lastName} {day}-{month}-{year}",
    Pointage_Dialog_Chantier: 'Chantier :',
    Pointage_Dialog_Compagnon: 'Compagnon :',
    Pointage_Dialog_Commentaire: 'Commentaire :',

    //* Compagnons
    CompagnonDetails_PreviousLabel: 'Compagnons',
    CompagnonList_CreateButtonTitle: 'Créer un compagnon',
    CompagnonList_CreatePageTitle: 'Créer un compagnon',
    CompagnonList_SwitchTitle: 'Afficher uniquement les compagnons actifs',
    CompagnonList_User: 'Utilisateur',
    CompagnonList_Phone: 'Téléphone',
    CompagnonList_Trigram: 'Trigramme',
    CompagnonList_Contract: 'Contrat',
    CompagnonList_ContractType: 'Type de contrat',
    CompagnonList_Role: 'Rôle',
    CompagnonList_State: 'État',
    CompagnonList_Filter_All: 'Tous',
    Compagnon_ContractCDD: 'CDD',
    Compagnon_ContractCDI: 'CDI',
    Compagnon_Contract_CDI_CDD: 'CDI/CDD',
    Compagnon_ContractAlternant: 'Alternant',
    Compagnon_ContractInterim: 'Intérimaire',

    Compagnon_RoleCompagnon: 'Compagnon',
    Compagnon_RoleAdmin: 'Administrateur',
    Compagnon_RoleConducteurTravaux: 'Conducteur de travaux',
    Compagnon_AgencyIsb: 'ISB',
    Compagnon_AgencyLom: 'LOM',
    Compagnon_AgencyQua: 'QUA',
    Compagnon_AgencyOther: 'AUTRE',
    CompagnonDetails_TabInformationTitle: 'Informations générales',
    CompagnonDetails_TabControlTitle: 'Contrôles',
    CompagnonDetails_TabPointageTitle: 'Pointage',
    CompagnonDetails_TabHoursTitle: "Relevé d'heures",

    CompagnonDetails_InformationCompagnonLastName: "Nom de l'utilisateur*",
    CompagnonDetails_InformationCompagnonFirstName: "Prénom de l'utilisateur*",
    CompagnonDetails_InformationCompagnonPhone: 'Téléphone de l’utilisateur',
    CompagnonDetails_InformationCompagnonTrigram: 'Trigramme de l’utilisateur*',
    CompagnonDetails_InformationCompagnonRole: 'Rôle*',
    CompagnonDetails_InformationCompagnonAgence: 'Agence*',
    CompagnonDetails_InformationCompagnonContract: 'Contrat*',
    CompagnonDetails_InformationCompagnonActive: 'Utilisateur actif',
    CompagnonDetails_InformationCompagnonInactive: 'Utilisateur inactif',
    CompagnonDetails_InformationCompagnonHasAccess: "Accès à l'app ?",
    CompagnonDetails_InformationCompagnonHasAccessBO: 'Accès au back office ?',
    CompagnonDetails_InformationTypePointage: 'Type du pointage*',
    CompagnonDetails_InformationPointageAtelier: 'Atelier',
    CompagnonDetails_InformationPointageChantier: 'Chantier',
    CompagnonDetails_InformationCompagnonEmail: 'Email de l’utilisateur',
    CompagnonDetails_InformationCompagnonEmailRequired: 'Email de l’utilisateur*',
    Compagnon_comments: 'COMMENTAIRES:',
    CompagnonDetails_InformationAgencyEmail: 'Email de l’agence intérim',
    CompagnonDetails_InformationAgencyName: 'Nom de l’agence intérim*',
    CompagnonControl_Empty: 'Aucun contrôle disponible',

    CompagnonHeures_Start: 'Début:',
    CompagnonHeures_End: 'Fin:',
    CompagnonHeures_SignDate: 'Date de la signature:',
    CompagnonHeures_Empty: "Aucun relevé d'heures disponible",

    //* Select
    Select_Placeholder: '-',

    //* Causerie
    CauserieList_CreateButtonTitle: 'Créer une causerie',
    CauserieList_Causerie: 'Causerie',
    CauserieList_CreatedBy: 'Créée par',
    CauserieList_CompagnonNb: 'Nb de compagnons',
    CauserieList_Publication: 'Publication',
    CauserieInformations_Name: 'Nom de causerie*',
    CauserieInformations_Description: 'Description',
    CauserieInformations_Pdf: 'Joindre un fichier PDF',
    CauserieInformations_DeleteButtonTitle: 'Supprimer la causerie',
    CauserieInformations_PublishButtonTitle: 'Publier la causerie',

    CauserieCreate_Title: 'Créer une causerie',
    CauserieCreate_FieldMissing:
        'Pour enregistrer une causerie, vous devez lui attribuer un libellé',
    CauserieSavePublished_FieldMissing:
        'Pour publier la causerie, vous devez saisir au moins 1 titre, 1 description et joindre 1 pdf',
    CauserieDetails_PreviousLabel: 'Causeries',
    CauserieDetails_TabInformationsTitle: 'Informations générales',
    CauserieDetails_TabResultTitle: 'Résultats',
    CauserieDetails_Publication: 'Publiée le {day}/{month}/{year} par {trigram}',
    CauserieDetails_SuccessPublish: 'Causerie publiée avec succès',
    CauserieDetails_SuccessDelete: 'Causerie supprimée avec succès',
    CauserieDetails_DeleteDialogTitle: 'Suppimer la causerie',
    CauserieDetails_DeleteDialogContent: 'Voulez vous vraiment supprimer la causerie ?',
    CauserieDetails_DeleteDialogLeftAction: 'Supprimer',
    CauserieDetails_DeleteDialogRightAction: 'Annuler',

    CauserieDetails_ResultCompagnon: 'Compagnon',
    CauserieDetails_ResultDate: 'Date de la causerie',
    CauserieDetails_ResultWho: 'Qui',

    //* MESSAGE
    MessagePage_Title: 'Message à destination des compagnons',
    Message_Color_Label: 'Choix sur la couleur de la police :',
    Message_Color_Red: 'Rouge',
    Message_Color_Black: 'Noir',
    Message_Color_Green: 'Vert',
    Message_Color_Blue: 'Bleu',
    Message_WrapperLabel: 'Contenu du message*',
    Message_PublishUnpublish: 'Publier / dépublier le message: ',
    Message_Published: 'Message publié',
    Message_NotPublished: 'Message non publié',
    Message_PlaceHolder: 'Saisir le message...',

    //* DIALOG
    Dialog_ConfirmTitle: 'Quitter',
    Dialog_ConfirmMessage:
        'Les modifications vont être perdues voulez vous vraiment quitter la page ?',
    Dialog_ConfirmCancel: 'Annuler',
    Dialog_ConfirmQuit: 'Quitter',

    //*Control PDF title
    Control_PdfTitleFormatGeneral: '{controlTitle} {year}{month}{day}-{hour}h{minutes}',
    Control_PdfTitleFormat:
        '{controlTitle} {affaireNumber} {affaireTitle} {year}{month}{day}-{hour}h{minutes}',

    Control_PdfDownloadProgress: 'Téléchargement en cours : {value}%',
    Control_DayDateFormat: '{year}-{month}-{day}',
};

const getFrMessages = () => fr;

export type StringKey = keyof ReturnType<typeof getFrMessages>;