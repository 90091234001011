import orange from '@material-ui/core/colors/deepOrange';
import { createTheme, Theme } from '@material-ui/core/styles';

export class ThemeMuiProvider {

    public static provideTheme = (): Theme => {
        return createTheme({
            palette: {
                primary: {
                    main: '#28b711',
                },
                secondary: orange,
                type: 'light',
            },
        });
    }
}