import React from 'react';
import { ButtonProps, ButtonClassType } from './Button/Button';
import { FormattedMessage } from 'react-intl';

import classNames from 'classnames';
import { IconSave, IconExportWhite, IconAddWhite, IconDelete, IconAddGreen } from './Icons/Icons';

export const getButtonSaveProps = (args?: any): ButtonProps => {
    return {
        title: <FormattedMessage id="COMMON_SAVE" />,
        variant: ButtonClassType.WithoutBorder,
        icon: IconSave,
        ...args,
    };
};

export const getButtonExportProps = (args?: any): ButtonProps => {
    return {
        title: <FormattedMessage id="COMMON_EXPORT" />,
        variant: ButtonClassType.Standard,
        icon: IconExportWhite,
        ...args,
    };
};

export const getButtonImportProps = (args?: any): ButtonProps => {
    return {
        title: <FormattedMessage id="COMMON_IMPORT" />,
        variant: ButtonClassType.Standard,
        icon: IconExportWhite,
        ...args,
    };
};

export const getButtonAddProps = (args?: any): ButtonProps => {
    return {
        variant: ButtonClassType.Standard,
        icon: IconAddWhite,
        ...args,
    };
};

export const getButtonDeleteProps = (args?: any): ButtonProps => {
    return {
        title: <FormattedMessage id="COMMON_DELETE" />,
        variant: ButtonClassType.Danger,
        icon: IconDelete,
        ...args,
    };
};
export const getControleQuestionButtonProps = (args?: any): ButtonProps => {
    return {
        icon: IconAddGreen,
        variant: ButtonClassType.Outlined,
        ...args,
    };
};

export const withClassSM = (name: string, isMobile: boolean) => {
    return { className: classNames({ [name]: true, xs: isMobile }) };
};
