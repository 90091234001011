import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import { AppState } from '../../../../../Configs/DetamStore';

export const IntlWrapper = ({ children }: React.PropsWithChildren<{}>) => {
    const i18n = useSelector((state: AppState) => state.I18n);
    return <IntlProvider {...i18n}>{children}</IntlProvider>;
};
