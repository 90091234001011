import { AppRoutes } from "../Core/App/AppRoutes";
import { AppRouteType } from "../Web/Components/Templates/AppRouter/AppRouteType";
import { getAffaireRoutes } from "../Web/Routes/AffaireRoutes";
import { getAuthRoutes } from "../Web/Routes/AuthRoutes";
import { getCauserieRoutes } from "../Web/Routes/CauserieRoutes";
import { getCompagnonRoutes } from "../Web/Routes/CompagnonRoutes";
import { getControleRoutes } from "../Web/Routes/ControleRoutes";
import { getMessageRoutes } from "../Web/Routes/MessageRoutes";
import { getPointageRoutes } from "../Web/Routes/PointageRoutes";

export class Routes {

    public static DEFAULT_ROUTE = AppRoutes.HOME;

    public static getAppRoutes = (): AppRouteType[] => [
        ...getAuthRoutes(),
        ...getControleRoutes(),
        ...getPointageRoutes(),
        ...getAffaireRoutes(),
        ...getCauserieRoutes(),
        ...getCompagnonRoutes(),
        ...getMessageRoutes(),
    ];
}
