import { NavigationService } from '../../Web/Services/NavigationService';
import { AppActionType } from '../App/AppActions';
import { Logger } from '../Errors/Logger';
import { AuthService } from '../Services/Data/Authentication/AuthenticationService';

import { ActiveDirectoryActionType } from '../Services/Data/Authentication/typings/ActiveDirectoryTypes';
import { AuthActionType } from '../Services/Data/Authentication/typings/AuthenticationActionTypes';


export const AuthenticationMiddleware = ({ dispatch, getState }: { dispatch: any; getState: any }) => (
    next: any
) => (action: any) => {
    if (action) {
        switch (action.type) {
            case AuthActionType.LOG_OUT:
                NavigationService.goToLogin();
                break;
            case AppActionType.APP_ESSENTIAL_RELOAD:
                const res = next(action);

                //* IGNORE ERROR
                AuthService.refreshToken(AppActionType.APP_ESSENTIAL_RELOAD)
                    .catch((err) => Logger.log(err));
                return res;
            case ActiveDirectoryActionType.LOGIN_AD_SUCCESS:
            case ActiveDirectoryActionType.REFRESH_AD_SUCCESS:
                //xhrClient.setAuthorization(`${action.payload.access_token}`);
                break;
            case ActiveDirectoryActionType.LOGIN_AD_FAIL:
            case ActiveDirectoryActionType.REFRESH_AD_FAIL:
                //xhrClient.setAuthorization('');
                break;
            default:
                break;
        }
    }

    return next(action);
};


