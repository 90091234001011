import { createSelector } from 'reselect';

/**
 * Our 'showLoader' selector.
 * @param {Object} state - the redux state
 * @param {Object} props - the props from the react component
 * @param {String[]} props.loadableStateNames - The array of keys in the redux state to check the 'initialiazed' key for
 * @returns {Boolean} if any of the sub states are NOT initialized
 */
const showLoaderSelector = (state, { loadableStateNames = [] }) =>
    loadableStateNames.some((subStateName) => !state[subStateName].initialized);

/** Returns our selector. Use this to create multiple instances  */
const makeLoadingGateSelector = () =>
    createSelector([showLoaderSelector], (showLoader) => ({ showLoader }));

/**
 * This is our custom 'mapStateToProps'. It returns another 'mapStateToProps', which returns the selector.
 * Returning another 'mapStateToProps' creates a new selector for each React component,
 * so each component will have their own selector, memoized to their props.
 */
export const makeMapStateToProps = () => {
    const loadingGateSelector = makeLoadingGateSelector();
    return (state, props) => loadingGateSelector(state, props);
};
