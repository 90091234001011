import { ApiClient, ApiClientOptions, UploadData } from './ApiClient';
import { XHRRequestConfig } from './XHRRequest';

let xhrClient: ApiClient;

export class ApiRequest {

    public static initializeApiClient = (options: ApiClientOptions): void => {
        xhrClient = new ApiClient(options);
    };

    public static async get<TResponse>(url: string, config?: XHRRequestConfig,): Promise<TResponse> {
        return xhrClient.get<TResponse>(url, config);
    }

    public static async getRaw<TResponse>(url: string, config?: XHRRequestConfig,): Promise<TResponse> {
        return xhrClient.getRaw<TResponse>(url, config);
    }

    public static async post<TResponse>(url: string, body: object, config?: XHRRequestConfig): Promise<TResponse> {
        return xhrClient.post<TResponse>(url, body, config);
    }

    public static async put<TResponse>(url: string, body: object, config?: XHRRequestConfig): Promise<TResponse> {
        return xhrClient.put<TResponse>(url, body, config);
    }

    public static async delete<TResponse>(url: string, config?: XHRRequestConfig): Promise<TResponse> {
        return xhrClient.delete<TResponse>(url, config);
    }

    public static async patch<TResponse>(url: string, body: object, config?: XHRRequestConfig): Promise<TResponse> {
        return xhrClient.patch<TResponse>(url, body, config);
    }

    public static async upload<TResponse>(url: string, uploadData: UploadData, config?: XHRRequestConfig): Promise<TResponse> {
        return xhrClient.upload<TResponse>(url, uploadData, config);
    }
}
