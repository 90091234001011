import { AppRoutes } from '../../Core/App/AppRoutes';
import { AppRouteType } from '../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';
import { LoadableListAffairePage } from '../Pages/Affaires/ListAffaire/LoadableListAffairePage';
import { LoadableAffaireDetailsPage } from '../Pages/Affaires/AffaireDetails/LoadableAffaireDetailsPage';
import { LoadableAffaireCreatePage } from '../Pages/Affaires/AffaireCreate/LoadableAffaireCreatePage';

const getListAffaireRoute = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.AFFAIRE_LIST, component: LoadableListAffairePage },
});

const getCreateAffaireRoute = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.AFFAIRE_CREATE, component: LoadableAffaireCreatePage },
});

const getAffaireDetailsRoute = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.AFFAIRE_DETAILS, component: LoadableAffaireDetailsPage },
});

export const getAffaireRoutes = () => [
    getListAffaireRoute(),
    getCreateAffaireRoute(),
    getAffaireDetailsRoute(),
];
