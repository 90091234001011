//* Sign in and read user profile
const MS_GRAPH_SCOPE_USER_READ: string = 'User.Read';
const MS_GRAPH_SCOPE_SITES_READ_ALL: string = 'Sites.Read.All';
const MS_GRAPH_SCOPE_FILES_READ_WRITE: string = 'Files.ReadWrite';

const XMF_SCOPE_ACCESS_AS_USER_DEV: string =
    'api://a17aa804-6df4-4785-8646-607593ae6794/access_as_user';

const XMF_SCOPE_ACCESS_AS_USER: string = '__XMF2_SCOPE_ACCESS_AS_USER__';

export class Constants {

    public static AUTH_STORAGE_KEY = 'detam-back-office-root';

    private static API_BASE_URL = '__API_HOST__';
    private static API_BASE_URL_DEV = 'https://detam-api-dev.azurewebsites.net';
    // private static API_BASE_URL_DEV = 'http://localhost:5001';

    private static AD_CLIENT_ID = '__AD_CLIENT_ID__';
    private static AD_CLIENT_ID_DEV = '35f174eb-6702-4728-b436-a3fbcb840547';

    private static AD_TENANT_ID = '__AD_TENANT_ID__';
    private static AD_TENANT_ID_DEV = 'd5f60f8f-e0c9-4f60-9e4e-46d36ec509ca';

    private static AD_REDIRECT_URI = '__AD_REDIRECT_URI__';
    private static AD_REDIRECT_URI_DEV = 'http://localhost:5000';

    public static DEFAULT_ITEM_COUNT_PER_PAGE = 25;

    public static DEFAULT_IMAGE_PREVIEW_SIZE = 124; //* Burk
    public static FILTER_ALL_VALUE = 'ALL';

    public static isDev = process.env.NODE_ENV === 'development';

    static getRoot = (): string =>
        Constants.isDev ? Constants.API_BASE_URL_DEV : Constants.API_BASE_URL;

    static getActiveDirectoryClientId = (): string =>
        Constants.isDev ? Constants.AD_CLIENT_ID_DEV : Constants.AD_CLIENT_ID;

    static getActiveDirectoryAuthority = (): string => {
        const tenantId = Constants.isDev ? Constants.AD_TENANT_ID_DEV : Constants.AD_TENANT_ID;
        return `https://login.microsoftonline.com/${tenantId}`;
    };

    static getActiveDirectoryRedirectUri = (): string => {
        return `${Constants.isDev ? Constants.AD_REDIRECT_URI_DEV : Constants.AD_REDIRECT_URI}/login`;
    };

    static getActiveDirectoryLogoutUri = (): string => {
        return `${Constants.isDev ? Constants.AD_REDIRECT_URI_DEV : Constants.AD_REDIRECT_URI}/login`;
    };

    static getXmf2Scope = (): string => {
        return Constants.isDev ? XMF_SCOPE_ACCESS_AS_USER_DEV : XMF_SCOPE_ACCESS_AS_USER;
    };

    static getActiveDirectoryScopes = (): Array<string> => [
        Constants.getXmf2Scope(),
        MS_GRAPH_SCOPE_USER_READ,
        MS_GRAPH_SCOPE_SITES_READ_ALL,
        MS_GRAPH_SCOPE_FILES_READ_WRITE,
    ];
}
