import { Route } from 'react-router-dom';

import { LoadableLogin } from '../Pages/Auth/Login/LoadableLogin';

import { AppRouteType } from '../Components/Templates/AppRouter/AppRouteType';
import { AppRoutes } from '../../Core/App/AppRoutes';

const getLoginRoute = (): AppRouteType => ({
    render: Route,
    props: { path: AppRoutes.LOGIN, component: LoadableLogin },
});

export const getAuthRoutes = () => [
    getLoginRoute(),
];
