import { browserHistory } from '../../Configs/BrowserHistory';
import { AppRoutes } from '../../Core/App/AppRoutes';

//* Generic detail route
const getDetailsRoute = (id: string, detailsRoute: string): string =>
    `${detailsRoute}`.replace(':id', id);

export class NavigationService {
    public static getHomeLink = AppRoutes.HOME;

    public static goTo = (route: string, replace?: boolean): void => {
        if (replace) {
            browserHistory.replace(route);
        } else {
            browserHistory.push(route);
        }
    };

    public static goToHome = (): void => browserHistory.push(AppRoutes.HOME);
    public static goToLogin = (): void => browserHistory.push(AppRoutes.LOGIN);

    public static getAffaireDetails = (affaireId: string): string => getDetailsRoute(affaireId, AppRoutes.AFFAIRE_DETAILS);

    //* Controle Templates
    public static getControleTemplateCreate = AppRoutes.CONTROLE_LIST;
    public static getControleTemplateDetail = (id: string) => getDetailsRoute(id, AppRoutes.CONTROLE_DETAILS);

    public static goToControleTemplateDetail = (id: string, replace: boolean): void => NavigationService.goTo(getDetailsRoute(id, AppRoutes.CONTROLE_DETAILS), replace);

    public static goToAffaireDetails = (affaireId: string): void => browserHistory.push(getDetailsRoute(affaireId, AppRoutes.AFFAIRE_DETAILS));
    public static goToAffaireCreate = (): void => browserHistory.push(AppRoutes.AFFAIRE_CREATE);

    public static getCompagnonDetails = (compagnonId: string) => getDetailsRoute(compagnonId, AppRoutes.COMPAGNON_DETAILS);
    public static goToCompagnonCreate = () => browserHistory.push(AppRoutes.COMPAGNON_CREATE);
    public static goToCompagnonDetails = (compagnonId: string) => browserHistory.push(getDetailsRoute(compagnonId, AppRoutes.COMPAGNON_DETAILS));

    public static getCauserieDetails = (causerieId: string) => getDetailsRoute(causerieId, AppRoutes.CAUSERIE_DETAILS);
    public static goToCauserieCreate = () => browserHistory.push(AppRoutes.CAUSERIE_CREATE);
    public static goToCauserieList = () => browserHistory.push(AppRoutes.CAUSERIE_LIST);
    public static goToCauserieDetails = (causerieId: string) => browserHistory.push(getDetailsRoute(causerieId, AppRoutes.CAUSERIE_DETAILS));
}
