import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';

import { ConfirmDialog } from '../Components/Views/Dialogs/ConfirmDialog/ConfirmDialog';
import { DialogLayoutStyle } from '../Components/Views/Dialogs/DialogLayout/DialogLayout';
import { PlatformContextProvider } from '../Context/PlatformContext/PlatformContext';

export const DialogContainerId: string = 'CustomDialogContainerId';

export type DialogAction = () => void | Promise<void>;

export enum DialogStyle {
    Default,
    Danger,
}

let dialogContainer: Element | null = null;

const initializeDialogContainerIfNeeded = (): void => {
    if (dialogContainer === null) {
        dialogContainer = document.getElementById(DialogContainerId);
    }
};

const internalShowDialog = (content: ReactElement) => {
    initializeDialogContainerIfNeeded();
    ReactDOM.render(content, dialogContainer);
};

const closeDialog = (actionToDo: () => void) => {
    if (dialogContainer != null && ReactDOM.unmountComponentAtNode(dialogContainer)) {
        actionToDo();
    }
};

export class DialogService {
    public static showDialog = (
        title: string,
        content: string,
        leftActionTitle: string,
        rightActionTitle: string,
        leftAction: DialogAction,
        rightAction: DialogAction,
        onClosed: DialogAction,
        style?: DialogStyle
    ): void => {
        internalShowDialog(
            <PlatformContextProvider>
                <ConfirmDialog
                    open={true}
                    title={title}
                    message={content}
                    leftActionLabel={leftActionTitle}
                    leftAction={() => closeDialog(leftAction)}
                    rightActionLabel={rightActionTitle}
                    rightAction={() => closeDialog(rightAction)}
                    onClose={() => closeDialog(onClosed)}
                    style={
                        style === DialogStyle.Danger
                            ? DialogLayoutStyle.Danger
                            : DialogLayoutStyle.Default
                    }
                />
            </PlatformContextProvider>
        );
    };
}
