import { replace } from 'connected-react-router';

import { AppState } from '../../../../Configs/DetamStore';
import { StoreProvider } from '../../../../Configs/StoreProvider';
import { ApiError, HttpStatusCode } from '../../../../Services/Api/ApiError';
import { AppRoutes } from '../../../App/AppRoutes';
import { ActiveDiectoryService } from '../../../DataServices/ActiveDiectoryService';
import { MeDataService } from '../../../DataServices/MeDataService';
import { Logger } from '../../../Errors/Logger';
import { AuthResponse } from '../../../Models/Authentication/AuthResponse';
import { MeResponse } from '../../../Models/Me/Me';
import { AuthenticationActions } from './AuthenticationActions';
import { AuthenticationState } from './typings/AuthenticationState';
import { AuthUtils } from './utils/AuthUtils';

export enum AuthenticateResultType {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}


const dispatch = (action: any) => StoreProvider.dispatch(action);
const getState = (): AppState | undefined => StoreProvider.getState();

const getAuthState = (): Partial<AuthenticationState> => getState()?.Authentication || {}

export class AuthService {

    public static setToken = async (token: string) => {
        Logger.log('🍉🍉🍉🍉🍉 | setToken', token);

        const adRresponse: AuthResponse = AuthUtils.toAuthResponse(token);
        try {
            if (await AuthService.isAuthorized(adRresponse.ad_access_token)) {
                await dispatch(AuthenticationActions.loginSuccess(adRresponse));
                return Promise.resolve(AuthenticateResultType.SUCCESS);
            } else {
                throw new Error('Error when verify auhtorization');
            }
        } catch (error) {
            //* Error : like ClientAuthError => user close the popup login
            await dispatch(AuthenticationActions.loginFail(error));
            return Promise.resolve(AuthenticateResultType.FAILED);
        }
    };

    public static login = async (): Promise<AuthenticateResultType> => {
        try {
            const adRresponse: AuthResponse = await ActiveDiectoryService.login();

            if (await AuthService.isAuthorized(adRresponse.ad_access_token)) {
                await dispatch(AuthenticationActions.loginSuccess(adRresponse));
                return Promise.resolve(AuthenticateResultType.SUCCESS);
            } else {
                throw new Error('Error when verify auhtorization');
            }
        } catch (error) {
            //* Error : like ClientAuthError => user close the popup login
            await dispatch(AuthenticationActions.loginFail(error));
            return Promise.resolve(AuthenticateResultType.FAILED);
        }
    };

    public static logout = (reason?: string) => dispatch(AuthenticationActions.logout(reason));


    public static getAuthorization(reason?: string): Promise<string> {
        const authState = getAuthState();
        const { ad_access_token, expiration_date } = authState;

        Logger.log('🔥🌊🔥🌊🔥 | getAuthorization : ', { authState, reason });

        if (!ad_access_token || AuthUtils.authenticationIsExpired(expiration_date)) {
            return AuthService.refreshToken(reason)
                .then((res) =>
                    Promise.resolve(AuthUtils.getAuthorizationFromToken(res.ad_access_token))
                )
                .catch((err) => Promise.reject(err));
        }
        const authorization = AuthUtils.getAuthorizationFromToken(ad_access_token);
        return Promise.resolve(authorization);
    }

    public static async refreshToken(reason?: string, forceRefresh?: boolean): Promise<AuthResponse> {
        const authState = getAuthState();

        const {
            ad_access_token: existing_ad_access_token,
            expiration_date: existing_expiration_date,
        } = authState;

        const prevPathname = getState()?.router.location.pathname || '';

        Logger.log('🔥🔥🔥🔥🔥 | refreshToken from  : ', reason);
        Logger.log(authState);

        if (
            !forceRefresh &&
            existing_ad_access_token &&
            !AuthUtils.authenticationIsExpired(existing_expiration_date)
        ) {
            const authResult: AuthResponse = {
                ad_access_token: existing_ad_access_token,
                expiration_date: existing_expiration_date,
            };
            await dispatch(AuthenticationActions.refreshSuccess(authResult));
            console.log(
                '🔥🔥🔥🔥🔥 | refreshToken success [ FROM NOT EXPIRED AND EXISTING TOKEN ] result : ',
                authResult.ad_access_token
            );
            return authResult;
        }

        //* no existing acces token from store but can exist in msal cookies
        if (!existing_ad_access_token) {
            try {
                //* try get token from auth provider (MSAL)
                const authResult = await AuthService.getToken();
                Logger.log(
                    '🔥🔥🔥🔥🔥 | authProvider getToken success : with no existing acces token from store ',
                    authResult.ad_access_token
                );
                await dispatch(AuthenticationActions.refreshSuccess(authResult));
                return authResult;
            } catch (error) {
                //* no existing acces token from store AND Cannot get token from MSAL
                const errorMessage =
                    'No refresh token found, cannot refresh and will logout, need to login';
                const errorRetry = new Error(errorMessage);
                dispatch(AuthenticationActions.refreshFail(errorRetry));

                Logger.log(
                    '🔥🔥🔥🔥🔥 | refreshToken failed [ NO EXISTING TOKEN FOUND ] : ',
                    { error, errorRetry }
                );

                return Promise.reject(errorRetry);
            }
        } else {
            dispatch(AuthenticationActions.setInitialized(false, 'loading refresh')); //* is loading

            dispatch(replace(AppRoutes.LOGIN));
            const redirectToPreviousPage = () => {
                if (prevPathname !== AppRoutes.LOGIN) {
                    //* redirect to previous location
                    dispatch(replace(prevPathname));
                }
            };

            Logger.log('🔥🔥🔥🔥🔥', 'WILL REQUEST TOKEN FROM AUTH PROVIDER');
            Logger.log(authState);

            return AuthService.getToken()
                .then((response: AuthResponse) => {
                    dispatch(AuthenticationActions.refreshSuccess(response));
                    Logger.log(
                        '🔥🔥🔥🔥🔥 | authProvider getToken success : ',
                        response.ad_access_token
                    );
                    redirectToPreviousPage();
                    return Promise.resolve(response);
                })
                .catch((err) => {
                    const error = new Error(
                        `could not silently retrieve access token for auto refresh: ${err}`
                    );
                    Logger.log('🗯🗯🗯🗯🗯 | authProvider getToken failed : ', error);
                    //* will reject the error
                    dispatch(AuthenticationActions.refreshFail(error));
                    return Promise.reject(error);
                });
        }
    }

    private static async getToken(): Promise<AuthResponse> {
        return ActiveDiectoryService.getToken().then((response: AuthResponse) => Promise.resolve(response));
    }

    //#region nested

    private static isAuthorized = async (adAccessToken: string): Promise<boolean> => {
        try {
            const authoriation = AuthUtils.getAuthorizationFromToken(adAccessToken);
            const meReponse: MeResponse = await MeDataService.checkAccess(authoriation);

            Logger.log('🍉🍉🍉🍉🍉 | isAuthorized', meReponse);
            //Tous va bien
            return Promise.resolve(true);
        } catch (error) {
            Logger.log('🍉🍉🍉🍉🍉 | isAuthorized', error);

            //todo cast as ApiError
            const apiError = error as ApiError;
            const { httpStatusCode } = apiError;
            if (httpStatusCode === HttpStatusCode.Forbidden) {
                return Promise.resolve(false);
            }
            //* any other error
            return Promise.resolve(false);
        }
    };

    //#endregion
}

