import './loadingPage.scss';

import React from 'react';

import { Loader } from '../../Loader/Loader';
import { Logo } from '../../Logo/Logo';

export interface LoadingPageProps {}
const LoadingPage = (props: LoadingPageProps) => {
    return (
        <div className="app-loading-page">
            <div className="content-container">
                <Logo large className="app-logo" />
                <Loader />
            </div>
        </div>
    );
};

export { LoadingPage };
