import { addExtraLocaleData } from './Utils/addExtraLocaleData';

//🇫🇷
const SUPPORTED_LOCALES = ['fr'];

const setupIntl = (): Promise<boolean> => {
    return addExtraLocaleData();
};

export { setupIntl, SUPPORTED_LOCALES };
