import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LoadingGate } from '../LoadingGate/LoadingGate';
import { makeMapStateToProps } from './stateLoadingSelector';

const StateLoadingGate = connect(makeMapStateToProps)(LoadingGate);

StateLoadingGate.propTypes = {
    /** Keys of the redux state to check the 'initialized' key for. */
    loadableStateNames: PropTypes.arrayOf(PropTypes.string),
};

StateLoadingGate.defaultProps = {
    loadableStateNames: [],
};

export { StateLoadingGate };
