import { AppRoutes } from '../../Core/App/AppRoutes';
import { AppRouteType } from '../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';
import { LoadableMessagePage } from '../Pages/Message/LoadableMessagePage';

const getMessageRoute = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.MESSAGE, component: LoadableMessagePage },
});

export const getMessageRoutes = () => [getMessageRoute()];
