import * as internalLogger from 'loglevel';
import { Constants } from '../Constants';

export class Logger {

    public static log(...msg: any[]) {
        if (Constants.isDev) {
            internalLogger.log(msg);
        }
    }
}
