import Loadable from 'react-loadable';

import { AsyncPageLoading } from './AsyncPageLoading';

const DELAY_MS = 200;

const withAsyncLoading = (asyncPageImport: () => Promise<React.ComponentType>) =>
    Loadable({
        loader: asyncPageImport,
        loading: AsyncPageLoading,
        delay: DELAY_MS,
    });

export { withAsyncLoading };
