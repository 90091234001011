

import * as MeActionTypes from './typings/MeActionTypes';
import { MeInfo } from '../../../Models/Me/Me';

export class MeActions {
    static setInitialized = (initialized: boolean): MeActionTypes.SetInitializedAction => ({
        type: MeActionTypes.MeActionType.SET_INITIALIZED,
        payload: { initialized },
    });

    static setInfo = (info?: MeInfo): MeActionTypes.SetInfoAction => ({
        type: MeActionTypes.MeActionType.SET_INFO, payload: { info }
    });
}