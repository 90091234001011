import React from 'react';
import { Routes } from './Configs/Routes';

import { Setup, SetupInitialized } from './Setup';
import { AppRouter } from './Web/Components/Templates/AppRouter/AppRouter';
import { AppWrapper } from './Web/Components/Templates/Wrappers/AppWrapper/AppWrapper';
import { StateLoadingGate } from './Web/Components/Templates/Wrappers/StateLoadingGate/StateLoadingGate';
import { PlatformContextProvider } from './Web/Context/PlatformContext/PlatformContext';
import { DialogContainerId } from './Web/Services/DialogService';
import { ThemeMuiProvider } from './Web/Themes/ThemeMuiProvider';

const { storeConfig }: SetupInitialized = Setup.initialize();

export const Application: React.FunctionComponent = () => (
    <PlatformContextProvider>
        <AppWrapper
            store={storeConfig.store}
            persistor={storeConfig.persistor}
            withEssentialReload
            dialogContainerId={DialogContainerId}
            getMuiTheme={() => ThemeMuiProvider.provideTheme()}
        >
            <StateLoadingGate loadableStateNames={['Authentication']}>
                <AppRouter routes={Routes.getAppRoutes()} defaultRoote={Routes.DEFAULT_ROUTE} />
            </StateLoadingGate>
        </AppWrapper>
    </PlatformContextProvider>
);
