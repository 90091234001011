import React from 'react';
import { Button, ButtonClassType } from '../../Button/Button';
import { withClassSM } from '../../ViewFactory';

export interface DialogButtonType {
    title?: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    variant: ButtonClassType;
    disabled?: boolean;
    isMobile: boolean;
}

export const getDialogButton = ({
    title,
    onClick,
    variant,
    disabled = false,
    isMobile,
}: DialogButtonType) => {
    return (
        title && (
            <Button
                type="button"
                variant={variant}
                title={title}
                onClick={onClick}
                disabled={disabled}
                {...withClassSM('dialog-button', isMobile)}
            />
        )
    );
};
