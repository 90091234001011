
export enum ActiveDirectoryActionType {
    REFRESH_AD_SUCCESS = 'app/auth/ad/refresh/success',
    REFRESH_AD_FAIL = 'app/auth/ad/refresh/fail',
    LOGIN_AD_SUCCESS = 'app/auth/ad/login/success',
    LOGIN_AD_FAIL = 'app/auth/ad/login/fail',
}

export interface RefreshActiveDirectorySuccessAction {
    type: ActiveDirectoryActionType.REFRESH_AD_SUCCESS;
    payload: {
        ad_access_token: string;
        expiration_date?: string
    };
}

export interface RefreshActiveDirectoryFailureAction {
    type: ActiveDirectoryActionType.REFRESH_AD_FAIL;
    payload: { error: any };
}

export interface LoginActiveDirectorySuccessAction {
    type: ActiveDirectoryActionType.LOGIN_AD_SUCCESS;
    payload: {
        ad_access_token: string;
        expiration_date?: string
    };
}

export interface LoginActiveDirectoryFailureAction {
    type: ActiveDirectoryActionType.LOGIN_AD_FAIL;
    payload: { error: any };
}

export type ActiveDirectoryAction =
    | RefreshActiveDirectorySuccessAction
    | RefreshActiveDirectoryFailureAction
    | LoginActiveDirectorySuccessAction
    | LoginActiveDirectoryFailureAction;
