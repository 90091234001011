import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';

import { LoadableListCompagnonPage } from '../Pages/Compagnons/ListCompagnon/LoadableListCompagnonPage';

import { AppRouteType } from '../Components/Templates/AppRouter/AppRouteType';
import { AppRoutes } from '../../Core/App/AppRoutes';
import { LoadableCompagnonDetailsPage } from '../Pages/Compagnons/CompagnonDetails/LoadableCompagnonDetailsPage';
import { LoadableCompagnonCreatePage } from '../Pages/Compagnons/CompagnonCreate/LoadableCompagnonCreatePage';

const getListCompagnonRoute = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.COMPAGNON_LIST, component: LoadableListCompagnonPage },
});

const getCreateCompagnonRoute = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.COMPAGNON_CREATE, component: LoadableCompagnonCreatePage },
});

const getDetailsCompagnonRoute = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.COMPAGNON_DETAILS, component: LoadableCompagnonDetailsPage },
});

export const getCompagnonRoutes = () => [
    getListCompagnonRoute(),
    getCreateCompagnonRoute(),
    getDetailsCompagnonRoute(),
];
