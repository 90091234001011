import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import { AppRouteType } from './AppRouteType';
import { browserHistory } from '../../../../Configs/BrowserHistory';

export type AppRouterProps = { defaultRoote: string; routes: Array<AppRouteType> };

export const AppRouter = ({ defaultRoote, routes }: AppRouterProps) => {
    return (
        <ConnectedRouter history={browserHistory}>
            <Switch>
                {routes.map(({ render: Render, props }) => {
                    const { path, exact = true } = props;
                    return <Render key={path} {...props} exact={exact} />;
                })}
                <Redirect to={defaultRoote} />
            </Switch>
        </ConnectedRouter>
    );
};
