import './button.scss';

import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { ButtonClassType } from './Button';

export interface ButtonLinkProps {
    variant: ButtonClassType;
    className?: string;
    disabled?: boolean;
    link: string;
    getContent: () => React.ReactNode;
}

const ButtonLink = ({
    variant,
    className,
    disabled = false,
    link,
    getContent,
}: ButtonLinkProps) => {
    const rootClasses = classNames({
        'Button-root': true,
        'Button-link': true,
        [variant]: variant,
        [className as string]: className,
        'Button-disabled': disabled,
    });

    return (
        <Link className={rootClasses} to={link}>
            {getContent()}
        </Link>
    );
};

export { ButtonLink };
