import * as React from 'react';
import Loadable from 'react-loadable';

interface LazyLoaderProps {
    delay?: number;
    children: React.ReactElement;
}

const LazyLoader: React.FunctionComponent<LazyLoaderProps> = ({ delay = 5 * 1000, children }) => {
    const timeoutRef = React.useRef<NodeJS.Timeout>();

    React.useEffect((): (() => void) => {
        timeoutRef.current = setTimeout((): void => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Loadable.preloadAll().catch((err: any): void => {
                console.error('Error preloading all components ', err);
            });
        }, delay);
        return (): void => {};
    });

    return children;
};

export { LazyLoader };
