import { ActiveDirectoryActionType } from '../Services/Data/Authentication/typings/ActiveDirectoryTypes';
import { MeActions } from '../Services/Data/Me/MeActions';
import { MeService } from '../Services/Data/Me/MeService';


export const MeMiddleware = ({ dispatch }: any) => (next: any) => (action: any) => {
    if (action) {
        switch (action.type) {
            case ActiveDirectoryActionType.LOGIN_AD_SUCCESS:
            case ActiveDirectoryActionType.REFRESH_AD_SUCCESS:
                MeService.getMyInfo().catch((err: any) => err);
                break;
            case ActiveDirectoryActionType.LOGIN_AD_FAIL:
            case ActiveDirectoryActionType.REFRESH_AD_FAIL:
                dispatch(MeActions.setInitialized(true));
                break;
            default:
                break;
        }
    }
    return next(action);
};
