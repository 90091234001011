import './button.scss';

import MuiButton from '@material-ui/core/Button';
import classNames from 'classnames';
import React from 'react';

import { ButtonClassType } from './Button';

export interface ButtonClassicProps {
    variant: ButtonClassType;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
    getContent: () => React.ReactNode;
}

const ButtonClassic = ({
    variant,
    className,
    onClick,
    disabled = false,
    type,
    getContent,
}: ButtonClassicProps) => {
    return (
        <MuiButton
            className={classNames({
                'Button-root': true,
                [variant]: variant,
                [className as string]: className,
                'Button-disabled': disabled,
            })}
            onClick={onClick}
            disabled={disabled}
            type={type}
        >
            {getContent()}
        </MuiButton>
    );
};

export { ButtonClassic };
