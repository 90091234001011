import Axios, { AxiosRequestConfig, Canceler } from 'axios';

export class XHRRequestCanceler {
    canceler: Canceler | null;
    canceled: boolean | undefined;

    constructor() {
        this.canceler = null;
    }
    setCanceler = (canceler: Canceler | null) => {
        this.canceler = canceler;
    }

    cancel = (reason?: string): void => {
        if (this.canceler !== null) {
            this.canceler(reason);
            this.canceled = true;
            this.canceler = null;
        }
    }
}

export interface XHRRequestConfig extends AxiosRequestConfig {
    noCheckError?: boolean;
    noAuth?: boolean;
    isRetryRequest?: boolean;
    retryCount?: number;
    progress?: (value: number) => void
    cts?: XHRRequestCanceler;
}


export const getAxiosRequestConfig = async (request: XHRRequestConfig = {}, getAuthorization?: (reason?: string) => Promise<string>): Promise<AxiosRequestConfig> => {
    const { cts, progress, noAuth = false } = request;

    const requestHeaders: Record<string, string> = noAuth
        ? request.headers :
        { ...request.headers, Authorization: await getAuthorization?.('from url : ' + request.url) };

    return {
        ...request,
        headers: requestHeaders,
        cancelToken: new Axios.CancelToken((canceler: Canceler): void =>
            cts?.setCanceler(canceler)
        ),
        onUploadProgress: (progressEvent: any) => {
            if (progress) {
                const percent =
                    Math.round((progressEvent.loaded * 100) / progressEvent.total) / 100;
                //* percent from 0.0 to 1.0
                progress(percent);
            }
        },
        onDownloadProgress: (progressEvent: any) => {
            if (progress) {
                const percent =
                    Math.round((progressEvent.loaded * 100) / progressEvent.total) / 100;
                //* percent from 0.0 to 1.0
                progress(percent);
            }
        },
    };
};

