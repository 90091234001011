import { StoreProvider } from '../../../../Configs/StoreProvider';
import { MeDataService } from '../../../DataServices/MeDataService';
import { MeResponse } from '../../../Models/Me/Me';
import { MeActions } from './MeActions';

const dispatch = (action: any) => StoreProvider.dispatch(action);

export class MeService {

    public static getMyInfo = async (): Promise<MeResponse | any> => {

        try {
            await dispatch(MeActions.setInitialized(false));
            const response: MeResponse = await MeDataService.getMyInfo();

            await dispatch(MeActions.setInfo(response.data));
            await dispatch(MeActions.setInitialized(true));

            return Promise.resolve(response);
        } catch (error) {
            await dispatch(MeActions.setInfo());
            await dispatch(MeActions.setInitialized(true));
            return Promise.reject(error);
        }
    };
}
