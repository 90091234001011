import { DateUtils } from '../../../../Utils/DateUtils';
import { AuthResponse } from '../../../../Models/Authentication/AuthResponse';
import { addHours } from 'date-fns';

export class AuthUtils {
    public static getAuthorizationFromToken = (token: string) => `Bearer ${token}`;

    public static authenticationIsExpired(expirationDate: any) {
        return !expirationDate || DateUtils.isDateBeforeWithParse(expirationDate, new Date());
    };

    public static toAuthResponse(token: string): AuthResponse {
        const authResponse: AuthResponse = {
            ad_access_token: token,
            expiration_date: addHours(new Date(), 1).toISOString()
        };
        return authResponse
    };

    static getAuthorizationString: string;
}
