import * as Redux from 'redux';

import { AppState, store } from './DetamStore';

export class StoreProvider {

    public static getStore = (): Redux.Store => store;

    public static getState = (): AppState => StoreProvider.getStore().getState();
    public static dispatch = (action: any) => StoreProvider.getStore().dispatch(action);

    public static getMessages = (): Record<string, string> => StoreProvider.getState().I18n.messages;
}