import React from 'react';

//#region IndividualIconsImports
import icClose from '../../../assets/icons/ic_close.svg';
import icArrowRight from '../../../assets/icons/ic_arrowRightWhite.svg';
import clipboard from '../../../assets/icons/ic_clipboard.svg';
import selectArrowDown from '../../../assets/icons/ic_selectArrowDown.svg';
import selectArrowDownWhite from '../../../assets/icons/ic_selectArrowDownWhite.svg';
import close from '../../../assets/icons/ic_close.svg';
import icCloseWhite from '../../../assets/icons/ic_close_white.svg';
import icCloseBlack from '../../../assets/icons/ic_close_black.svg';
import search from '../../../assets/icons/ic_search.svg';
import addPicture from '../../../assets/icons/ic_addPicture.svg';
import addWhite from '../../../assets/icons/ic_addWhite.svg';
import icAddGreen from '../../../assets/icons/ic_add_green.svg';
import save from '../../../assets/icons/ic_save.svg';
import exportWhite from '../../../assets/icons/ic_exportWhite.svg';
import downloadGreen from '../../../assets/icons/ic_downloadGreen.svg';
import emptyList from '../../../assets/icons/ic_emptyList.svg';

import icDelete from '../../../assets/icons/ic_delete.svg';
import icDrag from '../../../assets/icons/ic_drag.svg';
import icRemove from '../../../assets/icons/ic_remove.svg';

import affaire from '../../../assets/icons/ic_affaire.svg';
import openLink from '../../../assets/icons/ic_openLink.svg';
import hashtag from '../../../assets/icons/ic_hashtag.svg';

import clock from '../../../assets/icons/ic_clockPointage.svg';
import car from '../../../assets/icons/ic_carWhite.svg';
import pdf from '../../../assets/icons/ic_pdf.svg';

import icCommentDisabled from '../../../assets/icons/ic_comment_disabled.svg';
import icCommentEnabled from '../../../assets/icons/ic_comment_enabled.svg';
import icCheckEmpty from '../../../assets/icons/ic_emptyCheck.svg';
import icCheckValid from '../../../assets/icons/ic_check_valid.svg';

import icCheckGreen from '../../../assets/icons/icCheckGreen.svg';

//#endregion

// #region IconsRadioImports
import radioChecked from '../../../assets/icons/RadioIcons/ic_radioChecked.svg';
import radioUnchecked from '../../../assets/icons/RadioIcons/ic_radioUnchecked.svg';
//#endregion

//#region IconsPaginationImports
import iconPaginationEllipsis from '../../../assets/icons/PaginationIcons/ic_pagination_ellipsis.svg';
import iconPaginationNext from '../../../assets/icons/PaginationIcons/ic_pagination_next.svg';
import iconPaginationPrevious from '../../../assets/icons/PaginationIcons/ic_pagination_previous.svg';
//#endregion

//#region IconsElementPickerImports
import addElement from '../../../assets/icons/ElementPicker/ic_addElement.svg';
import removeElement from '../../../assets/icons/ElementPicker/ic_removeElement.svg';
import triangle from '../../../assets/icons/ElementPicker/ic_triangle.svg';
//#endregion

//#region IconsArrowGreen
import arrowRightGreen from '../../../assets/icons/ic_arrowRightGreen.svg';
import arrowLeftGreen from '../../../assets/icons/ic_arrowLeftGreen.svg';
import arrowDownGreen from '../../../assets/icons/ic_arrowDownGreen.svg';
//#endregion

//#region IconsCarPointage
import carEnabled from '../../../assets/icons/ic_carEnabled.svg';
import carDisabled from '../../../assets/icons/ic_carDisabled.svg';
import carEnabledCircled from '../../../assets/icons/ic_carEnabledCircled.svg';
import carDisabledCircled from '../../../assets/icons/ic_carDisabledCircled.svg';
//#endregion

// #region IconsCheckbox
import icCheckboxChecked from '../../../assets/icons/ic_checkbox_checked.svg';
import icCheckboxUnchecked from '../../../assets/icons/ic_checkbox_unchecked.svg';
//#endregion

export interface IconProps {
    wrappedIcon: JSX.Element;
}

const Icon = ({ wrappedIcon }: IconProps): React.ReactElement => {
    return wrappedIcon;
};

// #region IndividualIcons
export const IconArrowDown = Icon({
    wrappedIcon: <img src={icClose} alt="icon-arrow-down" />,
});

export const IconArrowRight = Icon({
    wrappedIcon: <img src={icArrowRight} alt="icon-arrow-right" />,
});

export const IconClipboard = Icon({ wrappedIcon: <img src={clipboard} alt="clipboard icon" /> });

export const IconSelect = Icon({
    wrappedIcon: <img src={selectArrowDown} alt="arrow down icon" />,
});

export const IconCheckGreen = Icon({
    wrappedIcon: <img src={icCheckGreen} alt="check green icon" />,
});

export const IconSelectWhite = Icon({
    wrappedIcon: <img src={selectArrowDownWhite} alt="arrow down icon white" />,
});

export const IconClose = Icon({ wrappedIcon: <img src={close} alt="close icon" /> });
export const IconCloseWhite = Icon({
    wrappedIcon: <img src={icCloseWhite} alt="close icon white" />,
});
export const IconCloseBlack = Icon({
    wrappedIcon: <img src={icCloseBlack} alt="close icon black" />,
});

export const IconSearch = Icon({ wrappedIcon: <img src={search} alt="search icon" /> });

export const IconAddPicture = Icon({
    wrappedIcon: <img src={addPicture} alt={'add pic icon'} />,
});

export const IconAddWhite = Icon({
    wrappedIcon: <img src={addWhite} alt={'add icon'} />,
});

export const IconAddGreen = Icon({
    wrappedIcon: <img src={icAddGreen} alt={'add icon green'} />,
});

export const IconSave = Icon({ wrappedIcon: <img src={save} alt="save icon" /> });

export const IconExportWhite = Icon({ wrappedIcon: <img src={exportWhite} alt="export icon" /> });

export const IconDownloadGreen = Icon({
    wrappedIcon: <img src={downloadGreen} alt="download icon" />,
});

export const IconEmptyList = Icon({
    wrappedIcon: <img src={emptyList} alt="empty icon" />,
});

export const IconDelete = Icon({
    wrappedIcon: <img src={icDelete} alt="icon-delete" />,
});

export const IconDrag = Icon({
    wrappedIcon: <img src={icDrag} alt="icon-drag" />,
});

export const IconRemove = Icon({
    wrappedIcon: <img src={icRemove} alt="icon-remove" />,
});

export const IconAffaire = Icon({
    wrappedIcon: <img src={affaire} alt="affaire icon" />,
});

export const IconOpenLink = Icon({
    wrappedIcon: <img src={openLink} alt="open link icon" />,
});

export const IconHashtag = Icon({
    wrappedIcon: <img src={hashtag} alt="hashtag icon" />,
});

export const IconClock = Icon({
    wrappedIcon: <img src={clock} alt="clock icon" />,
});

export const IconCar = Icon({
    wrappedIcon: <img src={car} alt="car icon" />,
});

export const IconPDF = Icon({
    wrappedIcon: <img src={pdf} alt="pdf icon" />,
});

// #endregion

// #region IconsRadio
const IconRadioChecked = Icon({ wrappedIcon: <img src={radioChecked} alt="radio checked icon" /> });
const IconRadioUnchecked = Icon({
    wrappedIcon: <img src={radioUnchecked} alt="radio unchecked icon" />,
});

export const IconsRadio = { IconRadioChecked, IconRadioUnchecked };

//#endregion

//#region  IconsPagination
const IconPaginationPrevious = Icon({
    wrappedIcon: <img src={iconPaginationPrevious} alt="pagination-previous-icon" />,
});
const IconPaginationEllipsis = Icon({
    wrappedIcon: <img src={iconPaginationEllipsis} alt="pagination-ellipsis-icon" />,
});
const IconPaginationNext = Icon({
    wrappedIcon: <img src={iconPaginationNext} alt="pagination-next-icon" />,
});

export const CustomPaginationIcons = {
    IconPaginationPrevious,
    IconPaginationEllipsis,
    IconPaginationNext,
};
//#endregion

//#region IconsElementPicker
const IconAddElement = Icon({ wrappedIcon: <img src={addElement} alt="add element icon" /> });
const IconRemoveElement = Icon({
    wrappedIcon: <img src={removeElement} alt="remove element icon" />,
});
const IconTriangle = Icon({ wrappedIcon: <img src={triangle} alt="triangle icon" /> });

export const IconsElementPicker = { IconAddElement, IconRemoveElement, IconTriangle };
//#endregion

//#region IconsArrowGreen
const IconArrowRightGreen = Icon({
    wrappedIcon: <img src={arrowRightGreen} alt="arrow right green" />,
});

const IconArrowLeftGreen = Icon({
    wrappedIcon: <img src={arrowLeftGreen} alt="arrow left green" />,
});

const IconArrowDownGreen = Icon({
    wrappedIcon: <img src={arrowDownGreen} alt="arrow down green" />,
});

export const IconsArrowGreen = { IconArrowRightGreen, IconArrowLeftGreen, IconArrowDownGreen };
//#endregion

//#region IconsCarPointage
const IconCarEnabled = Icon({
    wrappedIcon: <img src={carEnabled} alt="car enabled" />,
});

const IconCarDisabled = Icon({
    wrappedIcon: <img src={carDisabled} alt="car disabled" />,
});

const IconCarEnabledCircled = Icon({
    wrappedIcon: <img src={carEnabledCircled} alt="car enabled circled" />,
});

const IconCarDisabledCircled = Icon({
    wrappedIcon: <img src={carDisabledCircled} alt="car disabled circled" />,
});

export const IconsCarPointages = {
    IconCarEnabled,
    IconCarDisabled,
    IconCarEnabledCircled,
    IconCarDisabledCircled,
};
//#endregion

// #region IconsCheckbox
export const IconCheckboxUnchecked = Icon({
    wrappedIcon: <img src={icCheckboxUnchecked} alt="checkbox unchecked icon" />,
});

export const IconCheckboxChecked = Icon({
    wrappedIcon: <img src={icCheckboxChecked} alt="checkbox checked icon" />,
});
//#endregion

export const IconEmptyCheck = Icon({
    wrappedIcon: <img src={icCheckEmpty} alt="radio checked icon" />,
});
export const IconValidCheck = Icon({
    wrappedIcon: <img src={icCheckValid} alt="radio checked icon" />,
});
export const IconCommentDisabled = Icon({
    wrappedIcon: <img src={icCommentDisabled} alt="radio checked icon" />,
});
export const IconCommentEnabled = Icon({
    wrappedIcon: <img src={icCommentEnabled} alt="radio checked icon" />,
});

export default Icon;
