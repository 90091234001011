export enum AppRoutes {
    HOME = '/today',

    //* Auth
    LOGIN = '/login',

    //* CONTROLES
    CONTROLE_TODAY = '/today',
    CONTROLE_LIST = '/controles',
    CONTROLE_DETAILS = '/controles/details/:id',
    CONTROLE_CREATE = '/controles/create',

    //* POINTAGES
    POINTAGES_LIST = '/pointages',

    //* AFFAIRES
    AFFAIRE_LIST = '/affaires',
    AFFAIRE_CREATE = '/affaires/create',
    AFFAIRE_DETAILS = '/affaires/:id',

    //* CAUSERIES
    CAUSERIE_LIST = '/causeries',
    CAUSERIE_DETAILS = '/causeries/:id',
    CAUSERIE_CREATE = '/causeries/create',

    //* COMPAGNONS
    COMPAGNON_LIST = '/compagnons',
    COMPAGNON_CREATE = '/compagnons/create',
    COMPAGNON_DETAILS = '/compagnons/:id',

    //* MESSAGE
    MESSAGE = '/message',
}
