import { ApiRequest } from '../../Services/Api/ApiRequest';
import { MeResponse } from '../Models/Me/Me';

const MeEndpoints = {
    ME: '/api/v1/back/compagnons/me',
};

export class MeDataService {
    public static getMyInfo = (): Promise<MeResponse> => {
        return ApiRequest.get<MeResponse>(MeEndpoints.ME);
    };

    public static checkAccess = (authorization: string): Promise<MeResponse> => {
        return ApiRequest.get<MeResponse>(
            MeEndpoints.ME,
            {
                headers: { Authorization: authorization },
                noCheckError: true,
                noAuth: true
            },
        );
    };
}
