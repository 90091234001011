import { AppRoutes } from '../../Core/App/AppRoutes';
import { AppRouteType } from '../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';
import { LoadableListPointagePage } from '../Pages/Pointages/ListPointage/LoadableListPointagePage';

export const getListPointageRoute = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.POINTAGES_LIST, component: LoadableListPointagePage },
});

export const getPointageRoutes = () => [getListPointageRoute()];
