import { DetamStore, DetamStoreConfig } from './Configs/DetamStore';
import { Constants } from './Core/Constants';
import { Logger } from './Core/Errors/Logger';
import { AuthService } from './Core/Services/Data/Authentication/AuthenticationService';
import { ApiRequest } from './Services/Api/ApiRequest';


export type SetupInitialized = { storeConfig: DetamStoreConfig };

export class Setup {

    public static initialize = (): SetupInitialized => {

        const storeConfig = DetamStore.getStore();

        //* Initialize api client
        ApiRequest.initializeApiClient({
            baseURL: Constants.getRoot(),
            timeout: 1000 * 60, //* 🕐 1 minute;
            getAuthorization: (reason?: string): Promise<string> => AuthService.getAuthorization(reason)
        })

        Logger.log('🚀🚀🚀🚀🚀 | initialize done');

        return { storeConfig };

        // return {
        //     store,
        //     routes,
        //     persistor,
        //     mountActions: [],
        //     defaultRoote: DEFAULT_ROUTE,
        //     dialogContainerId: DialogContainerId,
        // };
    };

}
