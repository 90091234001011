import { MeState } from './typings/MeState';

import * as MeActionTypes from './typings/MeActionTypes';

const initialState: MeState = {
    initialized: false,
    data: undefined,

};

export const MeReducer = (state = initialState, action: MeActionTypes.MeAction): MeState => {
    switch (action.type) {
        default:
            return state;
        case MeActionTypes.MeActionType.SET_INITIALIZED:
            return { ...state, initialized: action.payload.initialized };
        case MeActionTypes.MeActionType.SET_INFO:
            return { ...state, data: action.payload.info };
    }
};
