import { Theme } from '@material-ui/core/styles';
import React from 'react';

import { CustomDialogContainer } from '../../CustomDialogContainer/CustomDialogContainer';
import { CustomToastContainer } from '../../CustomToastContainer/CustomToastContainer';
import { MuiApp } from '../../MuiApp/MuiApp';
import { ReduxApp } from '../../ReduxApp/ReduxApp';
import { IntlWrapper } from '../IntlWrapper/IntlWrapper';
import { LazyLoader } from '../LazyLoader/LazyLoader';
import { MountActionsGate } from '../MountActionsGate/MountActionsGate';

export interface AppWrapperProps {
    store: any;
    persistor: any;
    content?: React.ReactNode;
    children?: React.ReactNode;

    //* Array of Promises to execute on mount
    readonly mountActions?: Array<() => Promise<boolean>>;
    //* Automatically setup react-intl & intl
    //* This does NOT setup the messages, only the locales & 'intl', if it needs to be imported
    setupIntl?: () => Promise<boolean>;
    //* If should trigger 'BaseActions.triggerEssentialReload'
    withEssentialReload: boolean;
    dialogContainerId: string;

    //* Mui Theme
    getMuiTheme: () => Theme;
}

export const AppWrapper = ({
    store,
    persistor,
    content,
    children,
    mountActions = [],
    setupIntl,
    withEssentialReload = false,
    dialogContainerId,
    getMuiTheme,
}: AppWrapperProps) => (
    <MuiApp getMuiTheme={getMuiTheme}>
        <ReduxApp store={store} persistor={persistor}>
            <MountActionsGate
                actions={mountActions}
                setupIntl={setupIntl}
                withEssentialReload={withEssentialReload}
            >
                <IntlWrapper>
                    <LazyLoader>
                        <div style={{ height: '100%' }}>
                            {children ? children : content}
                            <CustomToastContainer />
                            <CustomDialogContainer containerId={dialogContainerId} />
                        </div>
                    </LazyLoader>
                </IntlWrapper>
            </MountActionsGate>
        </ReduxApp>
    </MuiApp>
);
