import { AuthResponse } from '../../../Models/Authentication/AuthResponse';
import * as ActiveDirectoryActionTypes from './typings/ActiveDirectoryTypes';
import * as AuthenticationTypes from './typings/AuthenticationActionTypes';
import { AuthUtils } from './utils/AuthUtils';

export class AuthenticationActions {
    public static logout = (reason?: string): AuthenticationTypes.LogoutAction => ({
        type: AuthenticationTypes.AuthActionType.LOG_OUT,
        payload: { reason },
    });

    public static setInitialized = (
        initialized: boolean,
        reason: string
    ): AuthenticationTypes.SetInitializedAction => ({
        type: AuthenticationTypes.AuthActionType.SET_INITIALIZED,
        payload: { initialized, reason },
    });

    public static setAuthenticated = (
        isAuthenticated: boolean
    ): AuthenticationTypes.SetAuthenticatedAction => ({
        type: AuthenticationTypes.AuthActionType.SET_AUTHENTICATED,
        payload: { isAuthenticated },
    });

    public static setAuthorizationHeader = (
        value: string
    ): AuthenticationTypes.SetAuthorizationHeaderAction => ({
        type: AuthenticationTypes.AuthActionType.SET_AUTHORIZATION_HEADER,
        payload: { value },
    });

    public static clearAuthorizationHeader = (): AuthenticationTypes.ClearAuthorizationHeader => ({
        type: AuthenticationTypes.AuthActionType.CLEAR_AUTHORIZATION_HEADER,
    });

    //* REFRESH
    /**
     * Dispatches the success auth actions, and calls `UserActioner.retrieveAuthenticatedUserInfo`
     * @param {Object} result - The result of the successful refresh
     * @param {String} result.access_token
     * @param {String} result.refresh_token
     * @param {Number} result.expires_in
     * @param {String} result.token_type
     * @param {String} result.expiration_date: - ms since epoch
     * @returns {Promise<Object>} result - the result of the refresh, same as the login result
     */
    public static refreshSuccess = (result: AuthResponse) => async (dispatch: any) => {
        const authorization = AuthUtils.getAuthorizationFromToken(result.ad_access_token);

        await dispatch(ActiveDirectoryActions.refreshAdSuccess(result));
        await dispatch(AuthenticationActions.setAuthorizationHeader(authorization));
        await dispatch(AuthenticationActions.setAuthenticated(true));
        await dispatch(AuthenticationActions.setInitialized(true, 'refresh success'));

        return Promise.resolve(result);
    };

    /**
     * Dispatches the ActiveDirectory refresh failure
     * @throws {Promise<Error>} the error received
     */
    public static refreshFail = (error: any) => async (dispatch: any) => {
        await dispatch(AuthenticationActions.clearAuthorizationHeader());
        await dispatch(ActiveDirectoryActions.refreshActiveDirectoryFailure(error));
        await dispatch(AuthenticationActions.setAuthenticated(false));
        await dispatch(AuthenticationActions.setInitialized(true, 'refresh fail'));
        return error;
    };

    /**
     * Dispatches the success auth actions, and calls `UserActioner.retrieveAuthenticatedUserInfo`
     * @param {Object} result - The result of the successful login
     * @param {String} result.access_token
     * @param {String} result.refersh_token
     * @param {Number} result.expires_in
     * @param {String} result.token_type
     * @param {String} result.expiration_date: - ms since epoch
     * @returns {Promise<Object>} result - the result received
     */
    public static loginSuccess = (auth: AuthResponse) => async (dispatch: any) => {
        const authorization = AuthUtils.getAuthorizationFromToken(auth.ad_access_token);
        await dispatch(AuthenticationActions.setAuthorizationHeader(authorization));
        await dispatch(ActiveDirectoryActions.loginActiveDirectorySuccess(auth));
        await dispatch(AuthenticationActions.setAuthenticated(true));
        await dispatch(AuthenticationActions.setInitialized(true, 'login success'));
        return auth;
    };

    /**
     * Dispatches the ActiveDirectory login failure with the errror received
     * @param {Error} error
     * @throws {Promise<Error>} the error received
     */
    public static loginFail = (error: any) => async (dispatch: any) => {
        await dispatch(AuthenticationActions.clearAuthorizationHeader());
        await dispatch(ActiveDirectoryActions.loginActiveDirectoryFailure(error));
        await dispatch(AuthenticationActions.setAuthenticated(false));
        await dispatch(AuthenticationActions.setInitialized(true, 'login fail'));
        return error;
    };
}

export class ActiveDirectoryActions {
    public static refreshAdSuccess = (
        result: AuthResponse
    ): ActiveDirectoryActionTypes.RefreshActiveDirectorySuccessAction => ({
        type: ActiveDirectoryActionTypes.ActiveDirectoryActionType.REFRESH_AD_SUCCESS,
        payload: { ...result },
    });

    public static refreshActiveDirectoryFailure = (
        error: any
    ): ActiveDirectoryActionTypes.RefreshActiveDirectoryFailureAction => ({
        type: ActiveDirectoryActionTypes.ActiveDirectoryActionType.REFRESH_AD_FAIL,
        payload: { error },
    });

    public static loginActiveDirectorySuccess = (
        result: AuthResponse
    ): ActiveDirectoryActionTypes.LoginActiveDirectorySuccessAction => ({
        type: ActiveDirectoryActionTypes.ActiveDirectoryActionType.LOGIN_AD_SUCCESS,
        payload: { ...result },
    });

    public static loginActiveDirectoryFailure = (
        error: any
    ): ActiveDirectoryActionTypes.LoginActiveDirectoryFailureAction => ({
        type: ActiveDirectoryActionTypes.ActiveDirectoryActionType.LOGIN_AD_FAIL,
        payload: { error },
    });
}
