import { connectRouter, routerMiddleware as createRouterMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const browserHistory = createBrowserHistory();

export const RouterReducer = connectRouter(browserHistory);

export const RouterMiddleware = createRouterMiddleware(browserHistory);


