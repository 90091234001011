export enum HttpStatusCode {
    Ok = 200,
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    RequestTimeout = 408,
    InternalServersError = 500, //* many reasons,
    Unknown,
}

export interface ApiError {
    is_success?: boolean;
    error_code?: string;
    error_message: string;

    httpStatusCode: number;
    innerError?: any;
    checkError: boolean;

    isApiError: boolean;
}

export const toApiError = (error: any): ApiError => {

    if (error.isApiError) {
        return error as ApiError;
    } else {
        const { config, response } = error;

        //* Error can be trigged by axios interceptor or
        //* Rejected by apiClient and logged to user as a wrapped error
        //* in this case config, response are in object called errorResponse
        const checkError = config?.checkError || true;

        const error_code = response?.error_code || response?.data?.error_code || '';
        const error_message = response?.error_message || response?.data?.error_message || '';
        const status = response?.status;

        const httpStatusCode = status as HttpStatusCode;

        return {
            httpStatusCode,
            error_code,
            error_message,
            isApiError: !!httpStatusCode || error_code || error_message,
            checkError,
        };
    }
};

