import { AppRoutes } from '../../Core/App/AppRoutes';
import { AppRouteType } from '../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';
import { LoadableListCauseriePage } from '../Pages/Causeries/ListCauserie/LoadableListCauseriePage';
import { LoadableCauserieCreatePage } from '../Pages/Causeries/CauserieCreate/LoadableCauserieCreate';
import { LoadableCauserieDetailsPage } from '../Pages/Causeries/CauserieDetails/LoadableCauseriePage';

const getListCauserieRoute = (): AppRouteType => ({
    render: PrivateRoute,
    props: {
        path: AppRoutes.CAUSERIE_LIST,
        component: LoadableListCauseriePage,
    },
});

const getCauserieCreateRoute = (): AppRouteType => ({
    render: PrivateRoute,
    props: {
        path: AppRoutes.CAUSERIE_CREATE,
        component: LoadableCauserieCreatePage,
    },
});

const getCauserieDetailsRoute = (): AppRouteType => ({

    render: PrivateRoute,
    props: {
        path: AppRoutes.CAUSERIE_DETAILS,
        component: LoadableCauserieDetailsPage,
    },
});

export const getCauserieRoutes = () => [
    getListCauserieRoute(),
    getCauserieCreateRoute(),
    getCauserieDetailsRoute(),
];
