import { AppRoutes } from '../../Core/App/AppRoutes';
import { AppRouteType } from '../Components/Templates/AppRouter/AppRouteType';
import { PrivateRoute } from '../Components/Templates/AppRouter/PrivateRoute';
import { LoadableControleCreatePage } from '../Pages/Controles/ControleCreate/LoadableControleCreatePage';
import { LoadableControleListPage } from '../Pages/Controles/ControleList/LoadableControleListPage';
import { LoadableControleListTodayPage } from '../Pages/Controles/ListControleToday/LoadableControleListTodayPage';
import { LoadableControleDetailsPage } from '../Pages/Controles/ControleDetails/LoadableControleDetailsPage';

const getListControleToday = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.CONTROLE_TODAY, component: LoadableControleListTodayPage },
});

const getListControle = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.CONTROLE_LIST, component: LoadableControleListPage },
});

const getControleCreate = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.CONTROLE_CREATE, component: LoadableControleCreatePage },
});

const getControleDetails = (): AppRouteType => ({
    render: PrivateRoute,
    props: { path: AppRoutes.CONTROLE_DETAILS, component: LoadableControleDetailsPage },
});

export const getControleRoutes = () => [
    getListControleToday(),

    getListControle(),
    getControleCreate(),
    getControleDetails(),
];
