import { AuthenticationParameters, AuthError, AuthResponse, UserAgentApplication } from 'msal';

import { Constants } from '../Constants';
import { AuthResponse as AuthenticationReponse } from '../Models/Authentication/AuthResponse';
import { AuthUtils } from '../Services/Data/Authentication/utils/AuthUtils';

let msalInstance: UserAgentApplication | null = null;

const authRequest: AuthenticationParameters = {
    scopes: Constants.getActiveDirectoryScopes(),
    prompt: 'select_account'
};

export class ActiveDiectoryService {

    private static initializeIfNeeded = (): UserAgentApplication => {
        if (msalInstance === null) {
            msalInstance = new UserAgentApplication(
                {
                    auth: {
                        clientId: Constants.getActiveDirectoryClientId(),
                        authority: Constants.getActiveDirectoryAuthority(),
                        redirectUri: Constants.getActiveDirectoryRedirectUri(),
                        postLogoutRedirectUri: Constants.getActiveDirectoryRedirectUri(),
                    },
                    // system: {
                    //     loadFrameTimeout: 6000,
                    //     tokenRenewalOffsetSeconds: 300,
                    //     navigateFrameWait: 300
                    // },
                    cache: {
                        cacheLocation: 'localStorage',
                        storeAuthStateInCookie: true,
                    }
                });
            msalInstance.handleRedirectCallback((authErr: AuthError, response?: AuthResponse) => {
                console.log("🐛🐛🐛🐛🐛 | MSAL callback", { authErr }, { response })
            });
        }
        return msalInstance
    }

    public static login = (): Promise<AuthenticationReponse> => {
        const msalInstance = ActiveDiectoryService.initializeIfNeeded();
        if (!msalInstance && msalInstance !== null) {
            throw (new Error('loginAd: msalInstance is not initialized, cannot login'));
        }
        return msalInstance
            .loginPopup(authRequest)
            .then(ActiveDiectoryService.acquireAccessToken)
            .then(authResponse => AuthUtils.toAuthResponse(authResponse.accessToken));
    };

    public static getToken = (): Promise<AuthenticationReponse> => {
        const msalInstance = ActiveDiectoryService.initializeIfNeeded();
        if (!msalInstance && msalInstance !== null) {
            throw (new Error('getToken: msalInstance is not initialized, cannot login'));
        }
        return ActiveDiectoryService.acquireAccessToken()
            .then(authResponse => AuthUtils.toAuthResponse(authResponse.accessToken));
    }

    public logout = (): void => msalInstance?.logout();

    //#region nested

    private static acquireAccessToken = () => {
        const msalInstance = ActiveDiectoryService.initializeIfNeeded();
        if (!msalInstance && msalInstance !== null) {
            throw (new Error('login: acquireAccessToken is not initialized, cannot login'));
        }
        return msalInstance.acquireTokenSilent(authRequest)
            .then(accessToken => accessToken)
            .catch(silentError => {
                // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure due to consent or interaction required ONLY
                if (
                    silentError.indexOf('consent_required') !== -1 ||
                    silentError.indexOf('interaction_required') !== -1 ||
                    silentError.indexOf('login_required') !== -1
                ) {
                    return msalInstance.acquireTokenPopup(authRequest)
                        .then(
                            accessToken => Promise.resolve(accessToken),
                            popupError => Promise.reject(new Error(`could not retrieve access token with popup: ${popupError}`))
                        );
                }
                return Promise.reject(new Error(`could not silently retrieve access token for auto login: ${silentError}`));
            });
    }

    //#endregion
}


