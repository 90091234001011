import './button.scss';

import React from 'react';

import classNames from 'classnames';

import { ButtonClassic } from './ButtonClassic';
import { ButtonLink } from './ButtonLink';

import { Loader } from '../../Templates/Loader/Loader';

enum ButtonClassType {
    Standard = 'Button-standard',
    Outlined = 'Button-outlined',
    Danger = 'Button-danger',
    WithoutBorder = 'Button-withoutBorder',
    Login = 'Button-login',
}

export interface ButtonProps {
    icon?: React.ReactElement;
    title?: React.ReactNode;
    variant: ButtonClassType;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    loading?: boolean;
    disabled?: boolean;
    type?: 'submit' | 'reset' | 'button';
    isBusy?: boolean;
    link?: string;
    visible?: boolean;
}

const Button = ({
    title = '',
    icon,
    variant,
    className,
    onClick,
    disabled = false,
    type,
    isBusy,
    link,
    visible = true,
}: ButtonProps) => {
    const getContent = (): React.ReactNode => (
        <div className="Button-content">
            {icon && !isBusy && <div className="Button-icon">{icon}</div>}
            <div
                className={classNames({
                    'Button-title': true,
                    [variant]: variant,
                    'Button-busy': isBusy,
                })}
            >
                {isBusy ? <Loader /> : title}
            </div>
        </div>
    );

    if (!visible) {
        return null;
    }

    return link ? (
        <ButtonLink
            className={className}
            link={link}
            getContent={getContent}
            variant={variant}
            disabled={disabled}
        />
    ) : (
        <ButtonClassic
            className={className}
            onClick={onClick}
            getContent={getContent}
            variant={variant}
            type={type}
            disabled={disabled}
        />
    );
};

export { Button, ButtonClassType };
